import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Form,
  Space,
  Table,
  Modal,
  Pagination,
  Input,
  Upload,
  notification,
  Flex,
} from 'antd'
import { apiRequest } from '../helpers'
import { CCard } from '@coreui/react'
import { BsPencil, BsTrash3 } from 'react-icons/bs'
import { FaRegStopCircle, FaRegPlayCircle, FaRegCopy } from 'react-icons/fa'
import { FiUpload, FiDownload } from 'react-icons/fi'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import FormInputs from '../components/FormInputs'
import { apiDownloadRequest } from '../helpers/apiRequest'
import { useLanguage } from './context/LanguageContext'
import { AuthorizationContext } from './auth/AuthProvider'
import { Content } from 'antd/es/layout/layout'
import DropdownButton from 'antd/es/dropdown/dropdown-button'
import { DownOutlined } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './auth/MsAuthConfig'
import * as msal from '@azure/msal-browser'

const TableContent = ({
  url,
  item,
  items,
  parentParse,
  type,
  onRowSelect,
  refreshData,
}) => {
  //eslint-disable-next-line
  const itemUrl = process.env.REACT_APP_API + url
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [inputs, setInputs] = useState([])
  const [columns, setColumns] = useState([])
  const [permissions, setPermissions] = useState([])
  const [targetKeys, setTargetKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [entityId, setEntityId] = useState(null)
  const [skipMeta, setSkipMeta] = useState(false)
  const [refreshTable, setRefreshTable] = useState(0)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [showUpdateModal, setUpdateModal] = useState(false)
  const [showCreateModal, setCreateModal] = useState(false)
  const [usernameMS, setUsernameMS] = useState(null)
  const { userData } = useContext(AuthorizationContext)

  useEffect(() => {
    if (refreshData) {
      getTableContent(skipMeta)
    }

    /** At refresh verifies if the ms_profile exists in sessionStorage and if it does, sets the state.*/
    const profile = JSON.parse(sessionStorage.getItem('ms_profile'))
    if (!profile) return

    setUsernameMS(profile.username)

    //eslint-disable-next-line
  }, [refreshData, url])

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  })

  const [filter, setFilter] = useState({
    search: '',
    sortColumn: '',
    sortDirection: '',
    filters: [],
  })

  const [buttons, setButtons] = useState({
    create: false,
    delete: false,
    update: false,
    play: false,
    stop: false,
    download: false,
    upload: false,
    view: false,
  })

  const { Search } = Input

  const languageContext = useLanguage()

  useEffect(() => {
    getTableContent(skipMeta)
    //eslint-disable-next-line
  }, [pagination.current, pagination.pageSize, filter, refreshTable])

  useEffect(() => {
    /**Get data with columns when language change */
    if (!skipMeta) return
    getTableContent()

    //eslint-disable-next-line
  }, [userData.language])

  useEffect(() => {
    if (!form) return
    form.resetFields()
    //eslint-disable-next-line
  }, [showUpdateModal, showCreateModal])

  const localParse = (items, isTable = false) => {
    if (!items) return {}
    const processed = {}
    Object.keys(items).forEach(key => {
      //added && item === false to the condition because it was showing active only if true
      if (!items[key] && item === false) {
        processed[key] = ''
      } else if (key === 'config') {
        processed[key] = JSON.stringify(items[key], null, '\t')
      } else if (key === 'createdBy') {
        processed.createdBy = items['user']?.email
      } else if (key === 'id') {
        processed.key = items[key]
        processed[key] = items[key]
      } else if (key === 'ai') {
        /**Verifies if key is equal to 'smtp' and creates a new key named 'aiActive' and 'aiId' that corresponds with the columns from backend to verify the status of aiConnector in EmailAgent*/
        processed.aiActive = items[key]?.active
        processed.aiId = items[key]?.name
      } else if (key === 'smtp') {
        /**Verifies if key is equal to 'ai' and creates a new key named 'smtpActive' that corresponds with the columns from backend to verify the status of aiConnector in EmailAgent*/
        processed.smtpActive = items[key]?.active
      } else if (key === 'imap') {
        /**Verifies if key is equal to 'imap' and creates a new key named 'imapActive' that corresponds with the columns from backend to verify the status of aiConnector in EmailAgent*/
        processed.imapActive = items[key]?.active
      } else if (isTable && items[key] && items[key].name) {
        if (processed[key + 'Id']) processed[key + 'Id'] = items[key].name
        processed[key] = items[key].name
      } else if (!isTable && key === 'tenantId') {
        processed.tenantId = items[key]
      } else {
        processed[key] = items[key]
      }

      if (items.children) {
        processed = { ...processed, ...localParse(items.children) }
      }
    })

    return processed
  }

  const parse = parentParse ? parentParse : localParse

  const getTableContent = async (skip = false) => {
    const url = getPaginationUrl(skip)
    const result = await apiRequest(url, 'GET')
    if (!result?.ok || !result?.data?.data) return

    if (
      result.data.hasOwnProperty('inputs') &&
      result.data.hasOwnProperty('columns') &&
      result.data.hasOwnProperty('actions')
    ) {
      setColumns(result.data.columns)
      setInputs(result.data.inputs)
      setPermissions(result.data.permissions)
      setButtons({
        create: result.data.actions.includes('create'),
        delete: result.data.actions.includes('delete'),
        update: result.data.actions.includes('update'),
        copy: result.data.actions.includes('copy'),
        download: type === 'kit' && result.data.actions.includes('read'),
        upload: result.data.actions.includes('upload'),
        play: result.data.actions.includes('run'),
        stop: result.data.actions.includes('run'),
        view: type === 'task' && result.data.actions.includes('read'),
      })
      setSkipMeta(true)
    }

    setPagination(prevPagination => ({
      ...prevPagination,
      current: result.data.meta.currentPage,
      pageSize: result.data.meta.itemsPerPage,
      total: result.data.meta.totalItems,
    }))

    const r = result.data.data
    const c = result?.data?.columns
    /** Return current values if is not mobile */
    if (window.innerWidth > 400) {
      setData(r.map(item => parse(item, true)))
      return
    }

    /** Add labels to value in case it's mobile */

    if ((c?.length, r?.length)) {
      const dataForMobile = r.map(item => {
        const obj = parse(item, true)
        for (const key of Object.keys(obj)) {
          if (!c) break
          const col = c.find(item => item.key === key)
          if (col) obj[key] = col.label + ': ' + obj[key]
        }
        return obj
      })
      setData(dataForMobile)
    }
  }

  const getById = async id => {
    const result = await apiRequest(`${itemUrl}/${id}`, 'GET')
    if (!result.ok) return
    const transferComponent = form.getFieldsValue('permissions')

    if (transferComponent) {
      const targetPermissions = result?.data?.permissions?.map(permission =>
        permissions?.indexOf(permission)
      )

      setTargetKeys(targetPermissions)
    }

    const parsed = parse(result.data)
    form.setFieldsValue(parsed)
  }

  const handleDelete = async id => {
    const result = await apiRequest(`${itemUrl}/${id}`, 'DELETE')
    if (!result.ok) return
    setRefreshTable(prev => prev + 1)
    setDeleteModal(false)
    setEntityId(null)
  }

  /**Copies the code of the robot to the clipboard. */
  const copyToClipboard = record => {
    navigator.clipboard.writeText(record.code)
    notification.success({
      message: 'Success!',
      description: languageContext.t('Copied'),
      duration: 0.5,
    })
  }

  const handleDownload = async (id, fileName) => {
    await apiDownloadRequest(`${itemUrl}/download/${id}`, fileName)
  }

  const uploadProps = id => {
    return {
      name: 'file',
      action: `${itemUrl}/${id}`,
      withCredentials: true,
      onChange(info) {
        if (info.file.status === 'error') {
          notification.error({
            message: 'System error!',
            description: 'There was a problem uploading this file!',
          })
        }
        if (info.file.status === 'done') {
          notification.success({
            message: 'File uploaded',
            description: 'Your file was uploaded successfully!',
          })
        }
        setRefreshTable(prev => prev + 1)
      },
    }
  }

  const processValues = (inputs, values) => {
    inputs.forEach(input => {
      if (input.type === 'checkbox' || input.type === 'switch') {
        values[input.name] = !!values[input.name]
      } else if (input.type === 'number') {
        values[input.name] = parseInt(values[input.name] || 0)
      } else if (input.type === 'decimal') {
        values[input.name] = parseFloat(values[input.name] || 0)
      }
      if (input.children) processValues(input.children, values)
    })
  }

  const save = async values => {
    processValues(inputs, values)

    /**If the values object contains the key 'permissions', add the permissions state to values */
    if (Object.keys(values).includes('permissions')) {
      values.permissions = targetKeys.map(item => permissions[item])
    }

    /**If the values object contains the key 'folder', add also the username from sessionStorage to values for storing in DB*/
    if (Object.keys(values).includes('folder')) {
      const msProfile = JSON.parse(sessionStorage.getItem('ms_profile'))
      const refreshTokenItem = Object.keys(sessionStorage).filter(item =>
        item.includes('refreshtoken')
      )

      values = {
        ...values,
        username: msProfile.username,
        refreshTokenData: JSON.parse(sessionStorage.getItem(refreshTokenItem)),
      }
    }

    const apiUrlEndpoint = entityId ? `${itemUrl}/${entityId}` : `${itemUrl}`
    const method = entityId ? 'PATCH' : 'POST'
    const result = await apiRequest(apiUrlEndpoint, method, values)
    if (!result.ok) return
    setRefreshTable(prev => prev + 1) // Refresh table
    setEntityId(null)
    setTargetKeys(null)
    setUpdateModal(false)
    setCreateModal(false)
    form.resetFields()
  }

  const toggleTask = async record => {
    const result = await apiRequest(`${itemUrl}/${record.key}`, 'PATCH', {
      active: record.active === 'Off',
    })
    if (!result.ok) return
    setRefreshTable(prev => prev + 1) // Refresh table
  }

  const handleView = id => {
    /**Verifica daca utilizatorul are in profile permisiunea de result-read */
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    if (!profile.permissions.includes('result-read')) {
      notification.error({
        message: 'System error!',
        description: 'Unauthorized!',
      })
    } else {
      /** Trimitere utilizator la pagina de results pentru task-ul dorit*/
      window.location.assign(`/results/${id}`)
    }
  }

  const getTableColumns = columns => {
    if (!columns?.length) return []

    let list = []
    for (let col of columns) {
      const column = {
        title: col.label,
        dataIndex: col.name,
        key: col.name,
        sorter: true,
        render: text => String(text),
      }
      if (col.options) column.filters = col.options
      list.push(column)
    }

    /**Cel putin un buton cu exceptia create trebuie sa fie true pentru a aduga coloana de actiuni */
    let showAction = false

    for (const key of Object.keys(buttons)) {
      if (['create', 'read'].includes(key)) continue
      if (buttons[key]) {
        showAction = true
        break
      }
    }

    if (!showAction) return list
    list.push({
      title: languageContext.t('Action'),
      className: 'table-action-column',
      key: 'action',
      render: (text, record) => (
        <div className="icons-container">
          {buttons.update && (
            <BsPencil
              onClick={e => {
                e.stopPropagation()
                setUpdateModal(true)
                setEntityId(record.key)
                getById(record.key)
              }}
              title={languageContext.t('Update')}
              size={20}
              className="pencil-icon"
            />
          )}
          {buttons.delete && (
            <BsTrash3
              onClick={e => {
                e.stopPropagation()
                setDeleteModal(true)
                setEntityId(record.key)
              }}
              title={languageContext.t('Delete')}
              size={20}
              className="delete-icon"
            />
          )}
          {buttons.download && !!record.originalName && (
            <FiDownload
              onClick={e => {
                e.stopPropagation()
                handleDownload(record.key, record.originalName)
              }}
              title={languageContext.t('Download')}
              size={20}
              className="download-icon"
            />
          )}
          <Upload {...uploadProps(record.key)} showUploadList={false}>
            {buttons.upload && (
              <FiUpload
                title={languageContext.t('Upload')}
                size={20}
                className="upload-icon"
              />
            )}
          </Upload>
          {buttons.play && record.active === 'Off' && (
            <FaRegPlayCircle
              onClick={e => {
                e.stopPropagation()
                toggleTask(record)
              }}
              title={languageContext.t('Start')}
              size={20}
              className="play-icon"
            />
          )}
          {buttons.stop && record.active === 'On' && (
            <FaRegStopCircle
              onClick={e => {
                e.stopPropagation()
                toggleTask(record)
              }}
              title={languageContext.t('Stop')}
              size={20}
              className="stop-icon"
            />
          )}
          {buttons.view && (
            <MdOutlineRemoveRedEye
              title={languageContext.t('View')}
              size={25}
              onClick={e => {
                e.stopPropagation()
                handleView(record.key)
              }}
              className="view-icon"
            />
          )}
          {/* {buttons.log && (
            <MdOutlineRemoveRedEye
              title={languageContext.t('View')}
              size={25}
              onClick={e => {
                e.stopPropagation()
                handleLog(record.key)
              }}
              className="view-icon"
            />
          )} */}
          {buttons.copy && (
            <FaRegCopy
              onClick={e => {
                e.stopPropagation()
                copyToClipboard(record)
              }}
              title={languageContext.t('Copy')}
              size={20}
              className="copy-icon"
            />
          )}
        </div>
      ),
    })
    return list
  }

  const onChange = async (sorter, filters, extra) => {
    let direction = ''
    if (extra.order === 'ascend') direction = 'ASC'
    if (extra.order === 'descend') direction = 'DESC'

    setFilter({
      ...filter,
      sortDirection: direction,
      sortColumn: extra.field,
      filters: filters,
    })
  }

  const onSearch = (value, _e, info) => {
    setFilter({
      ...filter,
      search: value,
    })
  }

  const getPaginationUrl = skip => {
    let url = itemUrl
    if (pagination.pageSize) url += `?limit=${pagination?.pageSize}`
    if (pagination.current) url += `&page=${pagination.current}`
    if (filter.search) url += `&search=${filter.search}`
    if (skip) url += `&searchBy=${['true']}`

    if (filter.sortColumn && filter.sortDirection) {
      let key = filter.sortColumn
      if (key.includes('Id')) key = key.slice(0, key.length - 2) + '.name'
      url += `&sortBy=${key}:${filter.sortDirection}`
    }

    if (Object.keys(filter.filters).length) {
      Object.keys(filter.filters).forEach(key => {
        let ids = filter.filters[key]
        if (!Array.isArray(ids)) return
        if (key.endsWith('Id')) {
          const slicedKey = key.slice(0, key.length - 2)
          filter.filters = url += `&filter.${slicedKey}.id=$in:${ids.join(',')}`
        } else {
          url += `&filter.${key}=$in:${ids.join(',')}`
        }
      })
    }

    return url
  }

  //**--------------------------------------------------OneDrive connector code-------------------------------------------------- */

  const [treeData, setTreeData] = useState([])
  const [commonPath, setCommonPath] = useState('')

  useEffect(() => {
    const initialState = async () => {
      const microsoftAccount = JSON.parse(sessionStorage.getItem('ms_account'))

      if (!microsoftAccount) setTreeData([])
      if (item === 'OneDrive') {
        if (showCreateModal || showUpdateModal) await connectProps.handleLogin()
      }
    }

    initialState()
  }, [showCreateModal, showUpdateModal])

  useEffect(() => {
    if (treeData.length > 0) {
      const paths = treeData.map(item => item.path)
      const commonPathResult = findCommonItems(paths)

      setCommonPath(commonPathResult)
      form.setFieldValue('folder', commonPath)
    } else {
      setCommonPath('') // Reset commonPath if treeData is empty
    }
  }, [treeData])

  /**Iterates over an array and verifies how many times an item repeats itself */
  const findCommonItems = array => {
    const itemCounts = {}

    array.forEach(item => {
      itemCounts[item] = (itemCounts[item] || 0) + 1
    })

    const commonItems = array.filter(item => itemCounts[item] > 1)
    const uniqueCommonItems = [...new Set(commonItems)]
    return uniqueCommonItems[0] || ''
  }

  const parseTreeData = (data, parentKey = null) => {
    if (!data.length) return []

    const parsedData = data.map(item => ({
      title: item.name,
      id: item.id,
      key: !parentKey
        ? `0-${data.indexOf(item)}`
        : `${parentKey}-${data.indexOf(item)}`,
      isLeaf: item.name.includes('.') ? true : false,
      path: item.path,
    }))

    return parsedData
  }

  const treeDataProps = {
    treeData,
    commonPath,
    setCommonPath,
    async getFiles(id, key) {
      const dataById = await apiRequest(`${itemUrl}/files/${id}`, 'POST', {
        ms_account: JSON.parse(sessionStorage.getItem('ms_profile')),
      })
      const tree = [...treeData]
      insertChildrenById(tree, id, dataById)
      setTreeData(tree)
    },
  }

  const { instance, accounts } = useMsal()

  /**Refresh token implementation -------------------------> */
  useEffect(() => {
    /**5 minutes in seconds */
    const REFRESH_THRESHOLD = 300

    /**30 minutes in milliseconds */
    const TOKEN_CHECK_INTERVAL = 1800000

    /**Verifies and compares with REFRESH_THRESHOLD the expiry date from sessionStorage*/
    const checkTokenExpiry = () => {
      console.log('Checking if token is expired')
      const refreshTokenItem = Object.keys(sessionStorage).filter(item =>
        item.includes('refreshtoken')
      )

      const msRefreshToken = JSON.parse(
        sessionStorage.getItem(refreshTokenItem)
      )
      if (msRefreshToken) {
        const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
        const timeUntilExpiry = msRefreshToken.expiresOn - currentTime
        if (timeUntilExpiry <= REFRESH_THRESHOLD) {
          /**Token is about to expire or has expired, refresh it */
          refreshToken()
        }
      }
    }

    /**Verifies if the token is expired on a defined interval*/
    setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL)

    /**Check token expiry immediately after mounting */
    checkTokenExpiry()
  }, [])

  /**Refresh token function that sends the refreshToken to the backend and updates the AccessToken*/
  const refreshToken = async () => {
    /**Searches through sessionStorage for the refresh token object in order to send it to the backend */
    const refreshTokenItem = Object.keys(sessionStorage).find(item =>
      item.includes('refreshtoken')
    )

    const msRefreshToken = JSON.parse(sessionStorage.getItem(refreshTokenItem))
    /**If no refresh token in sessionStorage, return */
    if (!msRefreshToken) {
      console.error('Refresh token not found')
      return
    }

    /**Sends a POST request to the backend with the refresh token from sessionStorage to receive a new access token */
    try {
      const response = await apiRequest(`${itemUrl}/refresh`, 'POST', {
        refreshToken: msRefreshToken.secret,
      })

      if (!response) return

      const newAccessToken = response?.access_token

      if (newAccessToken) {
        const account = accounts[0]

        instance.setActiveAccount(account)

        try {
          await instance.acquireTokenSilent({
            account: account,
            scopes: [
              'Files.ReadWrite',
              'offline_access',
              'User.Read',
              'profile',
            ],
            forceRefresh: true,
            extraQueryParameters: {
              access_token: newAccessToken,
            },
          })

          console.log('Token refreshed successfully')
        } catch (error) {
          if (error instanceof msal.InteractionRequiredAuthError) {
            console.log('Interaction required. Redirecting for consent...')
            instance.acquireTokenPopup({
              scopes: [
                'Files.ReadWrite',
                'offline_access',
                'User.Read',
                'profile',
              ],
            })
          } else {
            console.error('Token acquisition failed', error)
          }
        }
      } else {
        console.error('Failed to retrieve new access token')
      }
    } catch (error) {
      console.error('Token refresh failed', error)
    }
  }

  /**Refresh token implementation -------------------------> */

  const connectProps = {
    usernameMS: usernameMS,
    /**Login function for Microsoft authentication */
    async handleLogin() {
      try {
        const result = await instance.loginPopup(loginRequest)

        if (!result) setUsernameMS(null)
        /**Sets the username from the response from Microsoft and stores it in SessionStorage for maintaining the state at refresh */

        setUsernameMS(result?.account?.username)
        sessionStorage.setItem(
          'ms_profile',
          JSON.stringify({
            username: result?.account?.username,
            access_token: result?.accessToken,
          })
        )

        const refreshTokenItem = Object.keys(sessionStorage).filter(item =>
          item.includes('refreshtoken')
        )

        /**Send response received after login to the backend in order to connect to Microsoft Graph */
        const directoryTree = await apiRequest(`${itemUrl}/connect`, 'POST', {
          access_token: result.accessToken,
          token_type: result.tokenType,
          refreshToken: JSON.parse(sessionStorage.getItem(refreshTokenItem)),
        })

        setTreeData(parseTreeData(directoryTree))
        setCommonPath(findCommonItems(directoryTree.map(item => item.path)))
      } catch (error) {
        console.log(error)
      }
    },
    /**Logout function for Microsoft authentication */
    async handleLogout() {
      /**Removes the Microsoft profile and disconnects user */
      sessionStorage.removeItem('ms_profile')

      setUsernameMS(null)
      setTreeData([])
      setCommonPath('')
      form.setFieldValue('folder', commonPath)
      try {
        const result = await instance.logoutPopup()
        if (!result) return
      } catch (error) { }
    },
  }
  /**Recursive function that verifies if the id of the item in state has the same id with the item that comes from the backend, and if it does, will add it to the children */
  const insertChildrenById = (tree, id, children) => {
    let found = false
    for (let branch of tree) {
      if (branch.id == id) {
        updateChildren(children, branch.key)
        branch.children = children
        return true
      }
      if (branch.children && branch.children.length) {
        found = insertChildrenById(branch.children, id, children)
        if (found) return true
      }
    }
    return found
  }

  /**Sets the key, title, isLeaf and path of the children */
  const updateChildren = (children, key) => {
    for (let i = 0; i < children.length; i++) {
      children[i].key = key + '-' + i
      children[i].title = children[i].name
      children[i].isLeaf = children[i].name.includes('.') ? true : false
      children[i].path = children[i].path
      delete children[i].name
    }
  }

  //**--------------------------------------------------OneDrive connector code--------------------------------------------------  */

  const transferData = {
    data: permissions,
    targetKeys: targetKeys,
    selectedKeys: selectedKeys,
    onChange(nextTargetKeys, direction, moveKeys) {
      /**Maps through nextTargetKeys and sets the target keys state to the permission at the indexes from nextTargetKeys*/
      const permissionsKeys = nextTargetKeys.map(key =>
        permissions.indexOf(permissions[key])
      )

      setTargetKeys(permissionsKeys)
    },
    /**Controls the behaviour of the selected item when they are changed */
    onSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    },
    render(item) {
      return item.title
    },
  }

  const handleCancelModal = () => {
    setCreateModal(false)
    setTargetKeys(null)
  }

  return (
    <CCard className="p-3 margin-bottom-container">
      <div className="header-container">
        <h3>{languageContext.t(items)}</h3>
        <div className="search-and-add-container">
          <Search
            placeholder={languageContext.t('Search')}
            onSearch={onSearch}
          />
          <Button
            onClick={e => {
              e.stopPropagation()
              setCreateModal(true)
              setEntityId(null)
            }}
            className={buttons.create ? 'dyn-btn-primary' : 'hide-content'}
          >
            {languageContext.t('Add')} {languageContext.t(item)}
          </Button>
        </div>
      </div>
      <Table
        bordered
        rowSelection={true}
        columns={getTableColumns(columns)}
        dataSource={data}
        pagination={false}
        rowKey={record => record.key}
        onChange={onChange}
        className={'dyn-table' + (onRowSelect ? ' selectable' : '')}
        onRow={(record, _index) => {
          if (onRowSelect)
            return {
              onClick: () => {
                const rowToSelect = document.querySelector(
                  `.ant-table-row[data-row-key="${record.key}"]`
                )
                // check if rowToSelect has .ant-table-row-selected class
                if (rowToSelect.classList.contains('ant-table-row-selected')) {
                  rowToSelect.classList.remove('ant-table-row-selected')
                  onRowSelect(null)
                  return
                }
                const selectedRows = document.querySelectorAll(
                  '.ant-table-row-selected'
                )
                Array.from(selectedRows).forEach(row => {
                  row.classList.remove('ant-table-row-selected')
                })
                rowToSelect.classList.add('ant-table-row-selected')
                onRowSelect(record)
              },
            }
          return {}
          // return {
          //   onSelect: () => {
          //     if (onRowSelect) onRowSelect(record)
          //   },
          // }
        }}
      />
      <Pagination
        className="form-pagination"
        defaultCurrent={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        showSizeChanger
        onChange={(page, pageSize) => {
          setPagination(prevPagination => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
          }))
        }}
      />
      {showDeleteModal && (
        <Modal
          title={`${languageContext.t('Delete')} ${languageContext.t(item)}`}
          open={showDeleteModal}
          onCancel={() => setDeleteModal(false)}
          onOk={() => handleDelete(entityId)}
          className="dyn-form-modal"
        >
          <p>{languageContext.t('DeleteMessage')}</p>
        </Modal>
      )}
      {(showCreateModal || showUpdateModal) && (
        <Modal
          zIndex={1100}
          title={
            showCreateModal
              ? `${languageContext.t('Add')} ${languageContext.t(item)}`
              : `${languageContext.t('Edit')} ${languageContext.t(item)}`
          }
          open={showCreateModal || showUpdateModal}
          onCancel={() => {
            setEntityId(null)
            handleCancelModal(false)
            setUpdateModal(false)
          }}
          centered
          footer={null}
          width={1000}
          maskClosable={false}
          className="dyn-form-modal"
        >
          <Form layout="vertical" onFinish={save} form={form}>
            <Content className="row">
              <FormInputs
                inputs={inputs}
                transferData={transferData}
                connectProps={connectProps}
                treeDataProps={treeDataProps}
              />
            </Content>

            <Space
              className="justify-content-between mt-4"
              style={{ display: 'flex' }}
            >
              <Flex justify="flex-start" className="form-footer-buttons">
                <Form.Item>
                  {showCreateModal && (
                    <Button className="dyn-btn-primary" htmlType="submit">
                      {languageContext.t('Submit')}
                    </Button>
                  )}
                  {showUpdateModal && (
                    <DropdownButton
                      icon={<DownOutlined />}
                      className=" dyn-btn-primary"
                      menu={{
                        items: [
                          {
                            label: languageContext.t('Clone'),
                            key: 'clone',
                          },
                        ],
                        onClick: e => {
                          setEntityId(null)
                          form.setFieldValue(
                            'name',
                            form.getFieldValue('name') + ' - Clone'
                          )
                          /**If the form contains permissions, set the permissions from which is cloned to the new clone*/
                          if (form.getFieldsValue()?.permissions)
                            form.setFieldValue('permissions', targetKeys)

                          form.submit()
                        },
                      }}
                      onClick={e => form.submit()}
                    >
                      {languageContext.t('Submit')}
                    </DropdownButton>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="default"
                    className="dyn-btn-secondary"
                    onClick={() => {
                      handleCancelModal(false)
                      setUpdateModal(false)
                    }}
                  >
                    {languageContext.t('Cancel')}
                  </Button>
                </Form.Item>
              </Flex>
            </Space>
          </Form>
        </Modal>
      )}
    </CCard>
  )
}
export default TableContent
