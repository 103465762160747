export const en = {
  Active: 'Active',
  Account: 'Account',
  Action: 'Action',
  Add: 'Add',
  Administration: 'Administration',
  Agents: 'Agents',
  Author: 'Author',
  Body: 'Body',
  AI: 'AI',
  Cancel: 'Cancel',
  Chatbot: 'Chatbot',
  Collapse: 'Collapse',
  Chatbots: 'Chatbots',
  Clone: 'Clone',
  Config: 'Config',
  Connectors: 'Connectors',
  Copy: 'Copy to clipboard',
  Content: 'Content',
  Database: 'Database',
  Databases: 'Databases',
  Roles: 'Roles',
  Date: 'Date',
  Debug: 'Debug',
  Delete: 'Delete',
  DeleteMessage: 'Are you sure you want to delete?',
  Download: 'Download',
  Edit: 'Edit',
  Email: 'Email',
  Emails: 'Emails',
  EmailConnectors: 'Email Connectors',
  EmailSorters: 'Email Sorters',
  Execution: 'Execution',
  Executing: 'Executing',
  Execute: 'Execute',
  Expand: 'Expand',
  name: 'Name',
  Enabled: ' Enabled',
  Copied: 'Copied to clipboard!',
  'AI Model': 'AI Model',
  'AI Temperature': 'AI Temperature',
  'AI Max Tokens': 'AI Max Tokens',
  'AI Context': 'AI Context',
  'AI Account': 'AI Account',
  'AI Connector': 'AI Connector',
  'AI Connectors': 'AI Connectors',
  'Executions scheduler': 'Executions scheduler',
  'IMAP Account': 'IMAP Account',
  'SMTP Account': 'SMTP Account',
  'Mark Emails as Read': 'Mark Emails as Read',
  'Identify spam emails': 'Identify spam emails',
  'Forward Emails': 'Forward Emails',
  'Reply to Original Sender': 'Reply to Original Sender',
  'Dry Run': 'Dry Run',
  'Include Recommended Reply in Forwarded Email':
    'Include Recommended Reply in Forwarded Email',
  'in preview mode': 'in preview mode',
  'Use custom chatbot content': 'Use custom chatbot content',
  'New task scheduler': 'New task scheduler',
  'Use custom email content': 'Use custom email content',
  'Custom Email Content': 'Custom Email Content',
  'Email Subject': 'Email Subject',
  'Email Content': 'Email Content',
  'Recur every days': 'Recur every days',
  'Include reply': 'Include Reply',
  'Set spam': 'Set spam',
  'Reply sender': 'Reply sender',
  'Mark as read': 'Mark as read',
  'Type Id': 'Type Id',
  'First name': 'First name',
  'Last name': 'Last name',
  From: 'From',
  Forward: 'Forward',
  forwardTo: 'Forward To',
  Hide: 'Hide',
  Home: 'Home',
  Kit: 'Kit',
  Kits: 'Kits',
  Needs: 'needs',
  Language: 'Language',
  Logs: 'Logs',
  Messages: 'Messages',
  Name: 'Name',
  now: 'now',
  Password: 'Password',
  Permissions: 'Permissions',
  Phone: 'phone',
  Preview: 'Preview',
  Process: 'Process',
  Results: 'Results',
  Reply: 'Reply',
  replyTo: 'Reply to',
  Robots: 'Robots',
  Robot: 'Robot',
  Options: 'Options',
  Role: 'Role',
  OneDrive: 'OneDrive',
  Running: 'Running',
  Run: 'Run',
  Search: 'Search',
  Schedule: 'Schedule',
  Scheduler: 'Scheduler',
  Show: 'Show',
  Start: 'Start',
  Subject: 'Subject',
  Storage: 'Storage',
  Stop: 'Stop',
  Submit: 'Submit',
  System: 'System',
  Tasks: 'Tasks',
  Task: 'Task',
  Tenant: 'Tenant',
  Tenants: 'Tenants',
  Theme: 'Theme',
  Thread: 'Thread',
  Threads: 'Threads',
  To: 'To',
  Type: 'Type',
  Yes: 'Yes',
  No: 'No',
  Update: 'Update',
  Upload: 'Upload',
  User: 'User',
  Users: 'Users',
  View: 'View',
  Recruiter: 'Recruiter',
  Recruiters: 'Recruiters',
  Resumes: 'Resumes',
  Resume: 'Resume',
  OneDrive: 'OneDrive',
  'OneDrive Share': 'OneDrive Share',
  Retrieve: 'Retrieve',
}
