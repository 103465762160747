import React, { useContext, useEffect, useState } from 'react'
import { useLanguage } from '../../../components/context/LanguageContext'
import { Table, Tabs, notification } from 'antd'
import { Content } from 'antd/es/layout/layout'
import AgentChatbotMessages from './ChatbotMessages'
import { apiRequest } from '../../../helpers'
import { AuthorizationContext } from '../../../components'

const AgentChatbotDetails = ({ agent, refreshData }) => {
  const languageContext = useLanguage()
  const [activeTabKey, setActiveTabKey] = useState('0')
  /**Sets state that contains <AgentChatbotMessages/> component with default key 0*/
  const [tabs, setTabs] = useState([])
  const { userData } = useContext(AuthorizationContext)

  useEffect(() => {
    setTabs([initialTab])
    setActiveTabKey('0')
  }, [agent.key, userData.language])

  const getMessagesByThreadId = async id => {
    /** Get messages by thread id */
    const result = await apiRequest(
      process.env.REACT_APP_API + `agents/chatbot/${agent.key}/threads/${id}`
    )
    if (!result.ok) {
      console.error('[ERROR] Problem retreaving thread messages!')
      return []
    }

    /** Return messages formated for table columns */
    return result?.data.map((item, index) => ({ ...item, key: item.id }))
  }

  /**Open a new Tabs component from state that contains child Table component. Default Message Tab key is 0*/
  const openNewTab = async (record, messageColumns) => {
    const recordId = (record.id + 1).toString()
    const messages = await getMessagesByThreadId(recordId - 1)

    setTabs(prevTabs => {
      const tabExists = prevTabs.find(tab => tab.key === recordId)
      if (tabExists) {
        setActiveTabKey(recordId)
        return prevTabs
      }
      const tab = {
        key: recordId,
        closable: true,
        label: `${languageContext.t(`Thread`)} ${recordId - 1}`,
        children: (
          <Content>
            <Table
              dataSource={messages}
              columns={messageColumns}
              rowKey={record => record.id}
              className="dyn-table"
            />
          </Content>
        ),
      }
      setActiveTabKey(recordId)
      return [...prevTabs, tab]
    })
  }

  const initialTab = {
    label: `${languageContext.t('Threads')}`,
    children: (
      <Content>
        <div>
          <AgentChatbotMessages agent={agent} openNewTab={openNewTab} />
        </div>
      </Content>
    ),
    key: '0',
    closable: false,
  }

  /**Filters through tabs state based on the target key received in onEdit and sets the state with the new tabs*/
  const removeTab = targetKey => {
    let newActiveKey = activeTabKey
    let lastIndex = -1

    const newTabs = tabs.filter((tab, i) => {
      if (tab.key === targetKey) {
        lastIndex = i - 1
      }
      return tab.key !== targetKey
    })

    if (newTabs.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newTabs[lastIndex].key
      } else {
        newActiveKey = newTabs[0].key
      }
    }

    setTabs(newTabs)
    setActiveTabKey(newActiveKey)
  }

  return (
    <Tabs
      className="mt-3"
      type="editable-card"
      hideAdd={true}
      activeKey={activeTabKey}
      onChange={key => setActiveTabKey(key)}
      onEdit={(targetKey, action) => {
        if (action === 'remove') {
          removeTab(targetKey)
        }
      }}
      items={tabs}
    />
  )
}

export default AgentChatbotDetails
