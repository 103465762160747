export const ro = {
  Account: 'Cont',
  Active: 'Activ',
  Action: 'Acțiuni',
  Add: 'Adaugare',
  Administration: 'Administrare',
  Agents: 'Agenti',
  Author: 'Autor',
  AI: 'AI',
  Body: 'Cuprins',
  Cancel: 'Anulare',
  Chatbot: 'Chatbot',
  Chatbots: 'Chatboti',
  Clone: 'Cloneaza',
  Collapse: 'Micsoreaza',
  Config: 'Configuratie',
  Connectors: 'Conectori',
  Content: 'Continut',
  Copy: 'Copiere in clipboard',
  Database: 'Baze de date',
  Databases: 'Baze de date',
  Roles: 'Roluri',
  Date: 'Data',
  Debug: 'Debug',
  Delete: 'Stergere',
  DeleteMessage: 'Sunteti sigur ca vreti sa stergeti?',
  Download: 'Descarca',
  Edit: 'Editare',
  Email: 'Email',
  Emails: 'Emailuri',
  EmailSorters: 'Sortatoare Email',
  Execution: 'Executie',
  Executing: 'Executarea',
  Execute: 'Executa',
  'AI Account': 'Cont AI',
  'AI Connector': 'Conector AI',
  'AI Connectors': 'Conectori AI',
  'AI Max Tokens': 'AI Max Tokeni',
  'AI Context': 'Context AI',
  Copied: 'Copiat in clipboard!',
  'Executions scheduler': 'Planificare execuții',
  'Dry Run': 'Dry Run',
  'in preview mode': 'in modul previzualizare',
  'Use custom chatbot content': 'Folosiți continut chatbot personalizat',
  'New task scheduler': 'Programator nou de sarcini',
  'Use custom email content': 'Utilizați conținut personalizat de e-mail',
  'Custom Email Content': 'Conținut personalizat de e-mail',
  'Email Subject': 'Subiect Email',
  'Email Content': 'Conținut Email',
  'Recur every days': 'Se repetă în fiecare zi',
  'Include reply': 'Include Răspuns',
  'Set spam': 'Setează ca spam',
  'Reply sender': 'Răspuns expeditor',
  'Mark as read': 'Marchează ca citit',
  'Type Id': 'Tip Id',
  'First name': 'Prenume',
  'Last name': 'Nume',
  'AI Model': ' Model AI',
  'AI Temperature': 'Temperatura AI',
  Expand: 'Mareste',
  Enabled: 'Activat',
  From: 'Din',
  Forward: 'Redirecţionare',
  forwardTo: 'Transmite',
  Hide: 'Ascunde',
  Home: 'Acasa',
  Kit: 'Kit',
  Kits: 'Kit-uri',
  Needs: 'nevoi',
  Language: 'Limba',
  Logs: 'Loguri',
  Name: 'Nume',
  name: 'Nume',
  now: 'acum',
  Messages: 'Mesaje',
  Password: 'Parola',
  Permissions: 'Permisiuni',
  Phone: 'telefon',
  Preview: 'Previzualizare',
  Process: 'Proces',
  Results: 'Rezultate',
  Robots: 'Roboti',
  Robot: 'Robot',
  Options: 'Opțiuni',
  Role: 'Rol',
  OneDrive: 'OneDrive',
  Reply: 'Raspuns',
  replyTo: 'Raspunde la',
  Running: 'Ruleaza',
  Run: 'Ruleaza',
  Search: 'Cautare',
  Schedule: 'Planificare',
  Scheduler: 'Planificări',
  Show: 'Afiseaza',
  Subject: 'Subiect',
  Start: 'Porneste',
  Storage: 'Stocare',
  Stop: 'Opreste',
  Submit: 'Salveaza',
  System: 'Sistem',
  Tasks: 'Activitati',
  Task: 'Activitate',
  Tenant: 'Companie',
  Tenants: 'Companii',
  Theme: 'Tema',
  Thread: 'Conversatia',
  Threads: 'Conversatii',
  To: 'La',
  Type: 'Tip',
  Yes: 'Da',
  No: 'Nu',
  Update: 'Editeaza',
  Upload: 'Incarca',
  User: 'Utilizator',
  Users: 'Utilizatori',
  View: 'Vizualizeaza',
  'Schedule Email': 'Planificator email',
  Recruiter: 'Recruter',
  Recruiters: 'Recruteri',
  Resumes: 'CV-uri',
  Resume: 'CV',
  OneDrive: 'OneDrive',
  'OneDrive Share': 'OneDrive Share',
  Retrieve: 'Incarca',
}
